<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search meeting"
          type="text"
        />
        <b-button
          v-b-modal.scheduleMeetingModal
          variant="primary mr-1"
        >
          <feather-icon icon="PlusIcon" />
          Schedule Meeting
        </b-button>
      </b-form-group>
    </div>

    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
    >
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                dropright
                no-caret
                size="sm"
                toggle-class="text-decoration-none"
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    class="text-body align-middle"
                    icon="MoreVerticalIcon"
                    size="16"
                  />
                </template>
                <b-dropdown-item @click="selectedRow = props.row.originalIndex; $bvModal.show('updateLinks')">
                  <feather-icon
                    class="mr-50"
                    icon="Edit2Icon"
                  />
                  <span>Update Links</span>
                </b-dropdown-item>
                <b-dropdown-item @click="selectedRow = props.row.originalIndex; $bvModal.show('addParticipants')">
                  <feather-icon
                    class="mr-50"
                    icon="PlusIcon"
                  />
                  <span>Add Participants</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <span v-else-if="props.column.field === 'buttons'">
            <b-button-group
              :name="`btn-${props.row.originalIndex}`"
              size="sm"
            >
              <b-button
                :href="prependHttps(props.row.meeting_link)"
                target="_blank"
                variant="outline-primary"
              >Join meeting</b-button>
              <b-button
                v-if="props.row.recording_link"
                :href="prependHttps(props.row.meeting_link)"
                target="blank"
                variant="outline-primary"
              >View recording</b-button>
            </b-button-group>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
    <b-modal
      id="scheduleMeetingModal"
      ok-title="Save"
      size="lg"
      title="Schedule Meeting"
      @ok="addMeeting"
    >
      <b-row>

        <b-col md="6">
          <b-form-group
            label="Operation"
            label-for="operation"
          >
            <treeselect
              id="operation"
              v-model="operationsId"
              :normalizer="normalizer"
              :options="operations"
              placeholder="Select Phase, Subphase or Activities"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Title"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="title"
              placeholder="Meeting title"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Link"
            label-for="meetingLink"
          >
            <b-form-input
              id="meetingLink"
              v-model="meetingLink"
              placeholder="Meeting Link"
            />
          </b-form-group>
        </b-col>

        <!-- start date and time -->
        <b-col md="6">
          <b-form-group
            class="mb-2"
            label="Start date & time"
            label-for="StartDateAndTime"
          >
            <flat-pickr
              id="StartDateAndTime"
              v-model="beginTime"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
              class="form-control"
              placeholder="Select start date & tIme"
            />
          </b-form-group>
        </b-col>
        <!-- end date and time -->
        <b-col md="6">
          <b-form-group
            class="mb-2"
            label="End date & time"
            label-for="EndDateAndTime"
          >
            <flat-pickr
              id="EndDateAndTIme"
              v-model="endTime"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: beginTime }"
              class="form-control"
              placeholder="Select end date & tIme"
            />
          </b-form-group>
        </b-col>

        <!-- meeting type-->
        <b-col md="6">
          <b-form-group
            class="mb-2"
            label="Select meeting type"
            label-for="MeetingType"
          >
            <v-select
              id="MeetingType"
              v-model="type"
              :get-option-label="option => option.label"
              :options="meetingTypeOptions"
              :reduce="option => option.label"
              placeholder="Select meeting type"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-modal>
    <b-modal
      v-if="rows.length"
      id="updateLinks"
      ok-title="Save"
      title="Update Meeting Links"
      @ok="updateLinks"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Meeting Link"
            label-for="updateMeetingLink"
          >
            <b-form-input
              id="updateMeetingLink"
              v-model="rows[selectedRow].meeting_link"
              placeholder="Meeting Link"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Recording Link"
            label-for="updateRecordingLink"
          >
            <b-form-input
              id="updateRecordingLink"
              v-model="rows[selectedRow].recording_link"
              placeholder="Recording Link"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="addParticipants"
      ok-only
      ok-title="Save"
      title="Add Participants"
      no-close-on-backdrop
      @ok="updateParticipants"
    >
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Select Startups"
            label-for="selectStartups"
          >
            <v-select
              id="selectStartups"
              v-model="startupParticipants"
              :get-option-label="option => option.title"
              :options="startups"
              :reduce="option => option.id"
              multiple
              placeholder="Select Startups"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Select mentors"
            label-for="selectMentors"
          >
            <v-select
              id="selectMentors"
              v-model="mentorParticipants"
              :get-option-label="option => option.full_name"
              :options="mentors"
              :reduce="option => option.id"
              multiple
              placeholder="Select Mentors"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BButtonGroup,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { getUserData } from '@/utils/auth'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BButtonGroup,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BOverlay,
    // FormBuilder,
    vSelect,
    Treeselect,
    flatPickr,
  },
  data() {
    return {
      title: '',
      type: '',
      beginTime: '',
      endTime: '',
      meetingLink: '',
      operationsId: null,
      status: '',
      mutationLoading: false,
      participants: [],
      meetingTypeOptions: [
        {
          label: 'webinar',
          id: 1,
        },
        {
          label: 'one-on-one',
          id: 2,
        },
        {
          label: 'multi participant',
          id: 3,
        },
      ],
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Meeting',
          field: 'title',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Operation',
          field(row) {
            return row.programs_operationstable.title
          },
        },
        {
          label: 'Time',
          field(row) {
            if (!row.begin_time) return '-'
            return `${new Date(row.begin_time)
              .toLocaleString()} to ${new Date(row.end_time)
              .toLocaleString()}`
          },
        },

        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Links',
          field: 'buttons',
          sortable: false,
        },
      ],
      rows: [],
      selectedOperation: null,
      startups: [],
      mentors: [],
    }
  },
  computed: {
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
        .filter(obj => obj.role === 'superadmin')
      return arr.map(e => e.organisation_id)
        .filter(obj => obj) // Remove nulls
    },
    startupParticipants: {
      get() {
        if (this.rows.length < 1) {
          return []
        }
        return this.rows[this.selectedRow].programs_meetparticipanttables.map(e => e.organization_id_id)
          .filter(e => e)
      },
      set(e) {
        this.rows[this.selectedRow].programs_meetparticipanttables = this.rows[this.selectedRow].programs_meetparticipanttables.filter(i => i.user_id_id !== null)
        this.rows[this.selectedRow].programs_meetparticipanttables.push(...e.map(val => ({
          organization_id_id: val,
          user_id_id: null,
          schedule_id_id: this.rows[this.selectedRow].id,
        })))
      },
    },
    mentorParticipants: {
      get() {
        if (this.rows.length < 1) {
          return []
        }
        return this.rows[this.selectedRow].programs_meetparticipanttables.map(e => e.user_id_id)
          .filter(e => e)
      },
      set(e) {
        this.rows[this.selectedRow].programs_meetparticipanttables = this.rows[this.selectedRow].programs_meetparticipanttables.filter(i => i.organization_id_id !== null)
        this.rows[this.selectedRow].programs_meetparticipanttables.push(...e.map(val => ({
          organization_id_id: null,
          user_id_id: val,
          schedule_id_id: this.rows[this.selectedRow].id,
        })))
      },
    },
  },
  methods: {

    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    toTimestamp(strDate) {
      const datum = Date.parse(strDate)
      return datum / 1000
    },
    addMeeting() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_programs_scheduletable_one(object: {begin_time: "${this.beginTime}", end_time: "${this.endTime}", meeting_link: "${this.meetingLink}", operations_id_id: "${this.operationsId}", program_id_id: "${this.$route.params.id}", status: "Scheduled", title: "${this.title}", type: "${this.type}", programs_meetparticipanttables:{data:{user_id_id: ${getUserData().id}}}}) {
            id
          }
        }`,
        update: (store, { data: { insert_programs_scheduletable_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_scheduletable_one.id ? 'Added successfully' : 'Failed to add',
              icon: insert_programs_scheduletable_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_scheduletable_one.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateLinks() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_programs_scheduletable_by_pk(pk_columns: {id: ${this.rows[this.selectedRow].id} }, _set: {recording_link: "${this.rows[this.selectedRow].recording_link}", meeting_link: "${this.rows[this.selectedRow].meeting_link}"}) {
            id
          }
        }`,
        update: (store, { data: { update_programs_scheduletable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_programs_scheduletable_by_pk.id ? 'Updated successfully' : 'Failed to update',
              icon: update_programs_scheduletable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_programs_scheduletable_by_pk.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateParticipants() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($objects: [programs_meetparticipanttable_insert_input!]!) {
          delete_programs_meetparticipanttable(where: {schedule_id_id: {_eq: ${this.rows[this.selectedRow].id}}}) {
            affected_rows
          }
          insert_programs_meetparticipanttable(objects: $objects) {
            affected_rows
          }
        }`,
        variables: {
          objects: this.rows[this.selectedRow].programs_meetparticipanttables,
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_scheduletable(order_by: {begin_time: desc}, where: {programs_meetparticipanttables: {user_id_id: {_eq: ${getUserData().id} }}, program_id_id: {_eq: ${this.$route.params.id}}}) {
            id
            title
            type
            begin_time
            end_time
            meeting_link
            recording_link
            programs_operationstable {
              title
            }
                       status
            programs_meetparticipanttables {
              schedule_id_id
              organization_id_id
              user_id_id
            }
          }
        }`
      },
      update: data => data.programs_scheduletable,
    },

    operations: {
      query() {
        return gql`{
            programs_operationstable(where: {program_id: {_eq: ${this.$route.params.id}}, operations_type: {_eq: "Phase"}}) {
                  id
                  title
                  programs_operationstables(where: {operations_type: {_eq: "SubPhase"}}) {
                    id
                    title
                    programs_operationstables {
                      id
                      title
                    }
                  }
                }
              }`
      },
      update: data => data.programs_operationstable,
    },
    mentors: {
      query() {
        return gql`{
          programs_mentorstable(where: {program_id: {_eq: ${this.$route.params.id} }}) {
            users_customuser {
              id
              full_name
            }
          }
        }`
      },
      update: data => data.programs_mentorstable.map(e => e.users_customuser),
    },
    startups: {
      query() {
        return gql`{
          programs_startupparticipants(where: {program_id: {_eq:  ${this.$route.params.id} }}) {
            programs_applicantstable {
              users_organizationtable {
                title
                id
              }
            }
          }
        }`
      },
      update: data => data.programs_startupparticipants.map(e => e.programs_applicantstable.users_organizationtable),
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
